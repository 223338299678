<template>
  <form
      novalidate
      @submit.prevent="validate"
    class="personal-information-view"
  >
    <Title>
      Личные данные
    </Title>

    <PersonalInformation
        ref="personalInformation"
    />

    <div
      class="personal-information-view__actions"
    >
      <Button
        type="submit"
      >
        Продолжить
      </Button>
      <Button
        back
        @click.native="back"
      >
        Назад
      </Button>
    </div>
  </form>
</template>

<script>
import './personal-information-view.scss'

import PersonalInformation from '@index/components/application/personal-information/PersonalInformation'
import Title from '@index/components/gui/title/Title'
import Button from '@index/components/gui/button/Button'

import store from '@index/store'

import openRoute from '@index/helpers/router/openRoute'

export default {
  name: 'PersonalInformationView',
    methods: {
      validate() {
          this.$refs.personalInformation.validate()
          const isPersonalValid = this.$refs.personalInformation.isValid

          isPersonalValid && this.submit();
      },
        async submit() {
            const { next_step, noValid } = await this.$store.dispatch('application/send', {
                contactData: {
                    ...this.$refs.personalInformation.form,
                },
            })

            if (noValid && Object.keys(noValid)) {
                const personalInfoError = this.$refs.personalInformation.formErrors;

                personalInfoError.firstname = (noValid.firstname === false && 'Невалидное значение') || ''
                personalInfoError.lastname = (noValid.lastname === false && 'Невалидное значение') || ''
                personalInfoError.patronymic = (noValid.patronymic === false && 'Невалидное значение') || ''
                personalInfoError.birthday = (noValid.birthday === false && 'Невалидное значение') || ''
                personalInfoError.gender = (noValid.gender === false && 'Невалидное значение') || ''
                personalInfoError.addrcity = (noValid.addrcity === false && 'Невалидное значение') || ''
            }

            openRoute(next_step)
        },
        back() {
            this.$router.push({ name: 'ContactInfo' })
        },
    },
  components: {
    PersonalInformation,
    Title,
    Button,
  },
    beforeRouteEnter(to, from, next) {
      const { contactData: { phone } } = store.getters['application/user']

        if (phone) {
            next()
        } else {
            store.commit('application/load', false)
            next({ name: 'ContactInfo' })
        }
    }
};
</script>
