import Router from '@index/router'

export default function (path) {
    switch (path) {
        case 'poll/contact':
        case 'contact': {
            Router.push({
                name: 'PersonalInfo',
            })
            break;
        }
        case 'poll/passport':
        case 'passport': {
            Router.push({
                name: 'PassportInfo',
            })
            break;
        }
        default: {
            window.location.href = window.location.origin + '/' + path;
        }
    }
}